import { z } from 'zod'

export const PalommaBank = z.enum([
  'alianza',
  'avvillas',
  'ban100',
  'bancamia',
  'bancoagrario',
  'bancocajasocial',
  'bancodebogota',
  'bancodeoccidente',
  'bancolombia',
  'bancoomeva',
  'bancopopular',
  'bbva',
  'cfa',
  'citibank',
  'coltefinanciera',
  'confiar',
  'coofinep',
  'coopcentral',
  'cotrafa',
  'dale',
  'daviplata',
  'davivienda',
  'falabella',
  'finandina',
  'gnbsudameris',
  'iris',
  'itau',
  'jpmorgancolombia',
  'lulo',
  'movii',
  'mundomujer',
  'nequi',
  'pichincha',
  'rappipay',
  'santander',
  'scotiabankcolpatria',
  'serfinanza',
  'union',
  'nu',
  'uala',
])

export type PalommaBank = z.infer<typeof PalommaBank>
