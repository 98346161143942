import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import type { CartPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'
import { getCartOwner } from '@centrito/app/store/slices/userData/utils/getCartOwner'

export interface GetUserCartResponse {
  newCart: CartPublicComposite
}

const getUserCart = createAsyncThunk<GetUserCartResponse, void, AppThunkApiConfig>(
  `${REDUCER_NAMES.USER_DATA}/getUserCart`,
  async (payload, thunkAPI) => {
    try {
      const currentState = thunkAPI.getState()
      const cartOwner = getCartOwner(currentState, thunkAPI.dispatch)
      const newCart = await trpcProxyClient.user.cart.get.query(cartOwner)

      return { newCart }
    } catch (error) {
      return thunkAPI.rejectWithValue(miniSerializeError(error))
    }
  },
)

export default getUserCart
