import type {
  FatalErrorArea,
  FatalErrorEngineer,
} from '@centrito/api/nest/platform/sentry/domain/schemas'
import { BaseError } from './base.error'

export class FatalError extends BaseError {
  public readonly area?: FatalErrorArea
  public readonly engineer?: FatalErrorEngineer

  constructor(
    error: unknown,
    { area, engineer }: { area?: FatalErrorArea; engineer?: FatalErrorEngineer },
  ) {
    super(error, 'FatalError')
    this.area = area
    this.engineer = engineer
  }
}
