import { z } from 'zod'
import { OrderPaymentMethod } from '@centrito/db'

export const PalommaPayinType = z.enum(['pse', 'bancolombiaButton', 'card'])
export type PalommaPayinType = z.infer<typeof PalommaPayinType>

export const PalommaPayinTypeMap: Record<PalommaPayinType, OrderPaymentMethod> = {
  [PalommaPayinType.Enum.pse]: OrderPaymentMethod.PALOMMA__PSE,
  [PalommaPayinType.Enum.bancolombiaButton]: OrderPaymentMethod.PALOMMA__BANCOLOMBIA,
  [PalommaPayinType.Enum.card]: OrderPaymentMethod.PALOMMA__CARD,
}
