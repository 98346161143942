import { z } from 'zod'
import {
  $Enums,
  PalommaDocumentType as DbPalommaDocumentType,
  GovernmentIDType,
} from '@centrito/db'

export const PalommaDocumentType = z.enum(['cc', 'ce', 'nit'])
export type PalommaDocumentType = z.infer<typeof PalommaDocumentType>

export const PalommaDocumentTypeMap: Record<PalommaDocumentType, DbPalommaDocumentType> = {
  [PalommaDocumentType.Enum.cc]: DbPalommaDocumentType.CC,
  [PalommaDocumentType.Enum.ce]: DbPalommaDocumentType.CE,
  [PalommaDocumentType.Enum.nit]: DbPalommaDocumentType.NIT,
}

export const PalommaDocumentTypeDBMap: Record<PalommaDocumentType, $Enums.GovernmentIDType> = {
  [PalommaDocumentType.Enum.cc]: $Enums.GovernmentIDType.COL_CEDULA_CIUDADANIA,
  [PalommaDocumentType.Enum.ce]: $Enums.GovernmentIDType.COL_CEDULA_EXTRANJERIA,
  [PalommaDocumentType.Enum.nit]: $Enums.GovernmentIDType.COL_NIT,
}

export const GovernmentIdTypeMapToPalommaDocumentType: Partial<
  Record<GovernmentIDType, PalommaDocumentType>
> = {
  [GovernmentIDType.COL_CEDULA_CIUDADANIA]: PalommaDocumentType.Enum.cc,
  [GovernmentIDType.COL_CEDULA_EXTRANJERIA]: PalommaDocumentType.Enum.ce,
  [GovernmentIDType.COL_NIT]: PalommaDocumentType.Enum.nit,
}
