export const MOBILE_WIDTH_STYLES = {
  maxWidth: 500,
  width: '100%',
  marginHorizontal: 'auto',
}

export const DEFAULT_ZOOM = 12
export const DEFAULT_ZOOM_WEB = 11
export const FOCUS_ZOOM_WEB = 15
export const MIN_ZOOM_WEB = 10
export const MAX_ZOOM_WEB = 25

export const DEFAULT_CENTER: { lat: number; lng: number } = {
  lat: 4.63708,
  lng: -74.0888,
}
export const USER_OUT_OF_RADAR_THRESHOLD_KM = 4
export const USER_OUT_OF_RADAR_WARNING_MESSAGE =
  'Centrito no esta disponible en tu zona. Solo operamos en Bosa, pero estamos expandiendo rapidamente.'

export const googleMapsContainerStyle = {
  width: '100%',
  height: '100%',
}

export const mapsStyle = [
  {
    featureType: 'administrative',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#444444',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        color: '#f2f2f2',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'all',
    stylers: [
      {
        saturation: -100,
      },
      {
        lightness: 45,
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'all',
    stylers: [
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        color: '#46bcec',
      },
      {
        visibility: 'on',
      },
    ],
  },
]

export const PLACES_AUTO_COMPLETE_SETTINGS = {
  requestOptions: {
    componentRestrictions: {
      country: 'co',
    },
  },
  debounce: 300,
  cache: 5 * 24 * 60 * 60,
}
