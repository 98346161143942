/**
 * Returns the time threshold in milliseconds based on the product's position in the session
 * @param index Position of the product in the session list
 * @returns Time threshold in milliseconds
 */
export const getTimeThreshold = (index: number): number => {
  if (index < 3) return 5 * 60 * 1000 // First 3 items (newest): 5 minutes
  if (index < 6) return 4 * 60 * 1000 // Next 3: 4 minutes
  if (index < 9) return 3 * 60 * 1000 // Next 3: 3 minutes
  if (index < 12) return 2 * 60 * 1000 // Next 3: 2 minutes
  return 60 * 1000 // Last 3 (oldest): 1 minute
}
