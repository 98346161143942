import isNil from 'lodash.isnil'
// In order to use uuidv4 we need to import 'react-native-get-random-values' for it to work on mobile
import 'react-native-get-random-values'
import { v4 as uuidv4 } from 'uuid'

export const migrateFromOne = (state: any): any => {
  // Check if sessionProductIds already has the new structure
  const hasNewStructure =
    Array.isArray(state.userData.sessionProductIds) &&
    state.userData.sessionProductIds.length > 0 &&
    typeof state.userData.sessionProductIds[0] === 'object' &&
    'timestamp' in state.userData.sessionProductIds[0]

  return {
    ...state,
    userData: {
      ...state.userData,
      // If already in new structure, keep it as is, otherwise migrate
      sessionProductIds: hasNewStructure
        ? state.userData.sessionProductIds
        : Array.isArray(state.userData.sessionProductIds)
          ? state.userData.sessionProductIds.map((id: string) => ({
              id,
              timestamp: Date.now(),
              scrollCount: 0,
            }))
          : [],
      anonymousCartId: isNil(state.userData.anonymousCartId)
        ? uuidv4()
        : state.userData.anonymousCartId,
      cart: {
        ...state.userData.cart,
        cart: {
          ...state.userData.cart.cart,
        },
      },
    },
  }
}
