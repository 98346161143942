import React from 'react'
import { Linking } from 'react-native'
import { Link } from 'solito/link'
import { isUnderMaintenance } from '@centrito/app/config'
import { Button, Stack, XStack, YStack } from '@centrito/ui/src'
import {
  ContentStandardText,
  DisplayText1,
  FeatureBoldText,
} from '@centrito/ui/src/components/text/new'
import {
  InstagramIcon,
  SamSamCloud,
  SamSamLogo,
  TikTokIcon,
  WhatsAppHelpButton,
} from './components'

export const ShopperDesktopBackground: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <React.Fragment>
      <XStack
        backgroundColor="$neutral900"
        width="100%"
        justifyContent="center"
        paddingVertical={5}
        $md={{
          display: 'none',
        }}
        $platform-web={{
          position: 'sticky',
          top: 0,
        }}
      >
        {isUnderMaintenance === 'false' && (
          <FeatureBoldText color="$baseWhite" fontSize={12}>
            ✅ Pagos contra entrega, envíos gratis 🤑 Más de 15 mil productos al mejor precio
          </FeatureBoldText>
        )}
      </XStack>

      <YStack
        width="100%"
        height="100%"
        $gtMd={{
          height: 'calc(100vh - 26px)',
        }}
        overflow="hidden"
        top={0}
        left={0}
        backgroundColor="$baseWhite"
        alignItems="center"
      >
        {children}

        <Stack
          position="absolute"
          top={20}
          left={30}
          $platform-web={{
            width: 'fit-content',
          }}
          $md={{
            display: 'none',
          }}
        >
          <Link href="/">
            <SamSamLogo />
          </Link>
        </Stack>

        <Stack
          position="absolute"
          left={30}
          bottom={20}
          $platform-web={{
            width: 'fit-content',
          }}
          $md={{
            display: 'none',
          }}
        >
          <ContentStandardText fontSize={12} color="$primary1000">
            Más simple, más confiable.
          </ContentStandardText>
          <ContentStandardText fontSize={12} color="$primary1000">
            Samsam ®
          </ContentStandardText>
        </Stack>

        <Stack
          position="absolute"
          right={30}
          top={20}
          $platform-web={{
            width: 'fit-content',
          }}
          $md={{
            display: 'none',
          }}
        >
          <WhatsAppHelpButton />
        </Stack>

        <XStack
          position="absolute"
          right={30}
          bottom={20}
          $platform-web={{
            width: 'fit-content',
          }}
          $md={{
            display: 'none',
          }}
          gap={7}
          alignItems="flex-start"
        >
          <DisplayText1 color="$primary1000" fontSize={14}>
            ¡Síguenos en redes!
          </DisplayText1>

          <XStack gap={10}>
            <Button
              unstyled
              padding={0}
              backgroundColor="transparent"
              borderWidth={0}
              icon={InstagramIcon}
              onPress={(): Promise<void> => Linking.openURL('https://instagram.com/samsam_col')}
            />
            <Button
              unstyled
              padding={0}
              backgroundColor="transparent"
              borderWidth={0}
              icon={TikTokIcon}
              onPress={(): Promise<void> =>
                Linking.openURL('https://www.tiktok.com/@samsam.oficial')
              }
            />
          </XStack>
        </XStack>

        <Stack
          position="absolute"
          left={0}
          top={70}
          $platform-web={{
            width: 'fit-content',
          }}
        >
          <SamSamCloud />
        </Stack>

        <Stack
          position="absolute"
          right={0}
          bottom={90}
          $platform-web={{
            width: 'fit-content',
            transform: 'scaleX(-1)',
            zIndex: 0,
          }}
        >
          <SamSamCloud />
        </Stack>
      </YStack>
    </React.Fragment>
  )
}
