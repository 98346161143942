export const migrateFromZero = (state: any): any => {
  return {
    ...state,
    userData: {
      ...state.userData,
      cart: {
        ...state.userData.cart,
        pricingData: {
          itemsCost: 0,
          itemsRevenue: 0,
          itemsTax: 0,
          subtotal: 0,
          shippingRevenue: 0,
          itemsDiscount: 0,
          cartDiscount: 0,
          discount: 0,
          total: 0,
          ...state.userData.cart.pricingData,
        },
      },
    },
  }
}
