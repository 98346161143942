import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import isNil from 'lodash.isnil'
import { v4 as uuidv4 } from 'uuid'
import type { CartPublicComposite } from '@centrito/api/nest/shopper/cart/domain/composites'
import { CartValidationError } from '@centrito/api/nest/shopper/cart/domain/errors'
import { type UpdateCartItemsPayload } from '@centrito/api/router/user/cart/computeCartItemPricing'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'
import { actions } from '@centrito/app/store/slices/userData'
import { getCartOwner } from '@centrito/app/store/slices/userData/utils/getCartOwner'
import metaClient from '@centrito/app/utils/services/analytics/meta'
import posthogClient from '@centrito/app/utils/services/analytics/posthog'

export type UpdateCartItemsResponse =
  | {
    newCart: CartPublicComposite
  }
  | {
    cartValidationError: string
  }

const captureShopperUpdateCartItemsEvent = ({
  quantityChange,
  phoneNumber,
}: {
  quantityChange: number
  phoneNumber?: string
}): void => {
  if (!isNil(phoneNumber)) {
    metaClient.captureCustomEvent('shopper_update_cart_items', { ph: phoneNumber })
  } else {
    metaClient.captureCustomEvent('shopper_update_cart_items')
  }
  posthogClient.captureCustomEvent('shopper_update_cart_items', { quantityChange })
}

const updateCartItem = createAsyncThunk<
  UpdateCartItemsResponse,
  UpdateCartItemsPayload,
  AppThunkApiConfig
>(`${REDUCER_NAMES.USER_DATA}/updateCartItem`, async (payload, thunkAPI) => {
  const { product, productVariant, productPresentation, quantityChange, pricingData } = payload
  if (!product || !productVariant) {
    throw new Error('Product and product variant are required')
  }

  const targetItem = {
    productId: product.product.id,
    productVariantId: productVariant.variant.id,
    sizeName: productPresentation.name,
    priceDiscountItem:
      pricingData.priceItems.priceDiscountItemPublic ?? pricingData.priceItemsDiscount ?? 0,
  }

  const cartItem = {
    cartItem: {
      id: uuidv4(),
      productSize: productPresentation.name,
      quantity: quantityChange,
      productPresentation: productPresentation,
    },
    product: {
      ...product,
      variants: [],
    },
    productVariant,
    pricingData: {
      ...pricingData,
      priceDiscountItemPublic: product.pricingData.discount,
    },
  }

  // Dispatch optimistic update
  thunkAPI.dispatch(
    actions.optimisticUpdateCartItem({
      targetItem,
      quantityChange,
      newCartItem: cartItem ?? undefined,
    }),
  )

  try {
    const currentState = thunkAPI.getState()
    const cartOwner = getCartOwner(currentState, thunkAPI.dispatch)

    captureShopperUpdateCartItemsEvent({
      quantityChange,
      phoneNumber: currentState.auth.authenticatedData?.phone,
    })

    try {
      const { newCart } = await trpcProxyClient.user.cart.updateCartItem.mutate({
        cartOwner,
        targetItem,
        quantityChange,
      })

      return { newCart }
    } catch (error) {
      if (error instanceof CartValidationError) {
        return { cartValidationError: error.message }
      }
      throw error
    }
  } catch (error) {
    // Revert optimistic update
    thunkAPI.dispatch(
      actions.optimisticUpdateCartItem({
        targetItem,
        quantityChange: -quantityChange,
        newCartItem: cartItem ?? undefined,
      }),
    )
    return thunkAPI.rejectWithValue(miniSerializeError(error))
  }
})
export default updateCartItem
