import * as Sentry from '@sentry/browser'
import { SENTRY_NORMALIZE_DEPTH } from '@centrito/common/sentry'
import { isProd } from '@centrito/app/config'

Sentry.init({
  dsn: 'https://eb3b06d8e262b9fa95c5aa0b14131ae4@o1168684.ingest.us.sentry.io/4505715535183872',
  transport: Sentry.makeFetchTransport,
  stackParser: Sentry.defaultStackParser,
  integrations: [
    Sentry.breadcrumbsIntegration(),
    Sentry.globalHandlersIntegration(),
    Sentry.linkedErrorsIntegration(),
    Sentry.dedupeIntegration(),
  ],
  enabled: isProd,
  normalizeDepth: SENTRY_NORMALIZE_DEPTH,
})

export default Sentry
