import { z } from 'zod'

export const AddProductEventSchema = z.object({
  product_id: z.string(),
  product_name: z.string(),
  product_image_url: z.string(),
})

export const RegisterEventSchema = z.object({
  source: z.string(),
  isMobile: z.boolean(),
})

type AddProductEventProperties = z.infer<typeof AddProductEventSchema>
type RegisterEventProperties = z.infer<typeof RegisterEventSchema>
export type CustomEventProperties = AddProductEventProperties | RegisterEventProperties
