import React from 'react'
import type { ErrorProps } from 'next/error'
import { useQueryClient } from '@tanstack/react-query'
import isNil from 'lodash.isnil'
import { useRouter } from 'solito/router'
import { useCanGoBack } from '@centrito/app/components/BackButton/useCanGoBack'
import NavBar from '@centrito/app/components/NavBar'
import NavBarLayout from '@centrito/app/layouts/NavBarLayout/NavBarLayoutPage'
import { YStack } from '@centrito/ui/src'
import { BigMainButton, HelpMainButton } from '@centrito/ui/src/components/button'
import { SadSamSamLogo } from '@centrito/ui/src/components/svgs/logos/SadSamSamLogo'
import { ButtonText, SubtitleText2 } from '@centrito/ui/src/components/text/new'

interface ErrorPageProps extends ErrorProps {
  onReset?: () => void
}

export const ErrorPage: React.FC<ErrorPageProps> = ({ statusCode, title, onReset }) => {
  const router = useRouter()
  const canGoBack = useCanGoBack()
  const queryClient = useQueryClient()

  const handleBackButtonPress = (): void => {
    queryClient.invalidateQueries()
    queryClient.removeQueries()
    onReset?.()
    if (canGoBack) {
      router.back()
    } else {
      router.push('/')
    }
  }
  return (
    <NavBarLayout>
      <NavBar isPaddingTopEnabled isBottomLineEnabled>
        <NavBar.Right>
          <HelpMainButton hasNoMargins />
        </NavBar.Right>
      </NavBar>
      <YStack width="100%" flexGrow={1} alignItems="center" justifyContent="center" gap="$10">
        <YStack width="80%" gap="$5" alignItems="center">
          <SadSamSamLogo />
          <YStack alignItems="center">
            <SubtitleText2 textAlign="center">
              {!isNil(title)
                ? title
                : statusCode === 404
                  ? 'Ups. No hemos encontrado la página que buscas.'
                  : 'Ups. Algo ha salido mal!'}
            </SubtitleText2>
            <SubtitleText2>{statusCode}</SubtitleText2>
          </YStack>
          <BigMainButton onPress={handleBackButtonPress}>
            <ButtonText color="white">{canGoBack ? 'Volver atrás' : 'Volver al inicio'}</ButtonText>
          </BigMainButton>
        </YStack>
      </YStack>
    </NavBarLayout>
  )
}
