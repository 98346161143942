import omit from 'lodash.omit'
import pick from 'lodash.pick'
// In order to use uuidv4 we need to import 'react-native-get-random-values' for it to work on mobile
import 'react-native-get-random-values'
import { v4 as uuidv4 } from 'uuid'
import { $Enums } from '@centrito/db'

export const migrateFromNegativeOne = (state: any): any => {
  return {
    ...state,
    userData: {
      ...state.userData,
      userProfile: state.userData?.userProfile || null,
      categoryIndex: state.userData?.categoryIndex || 0,
      cart: {
        items:
          state.userData.cart.items?.map((item: any) => ({
            product: {
              ...pick(item.product, ['category', 'merchant', 'highestCampaign']),
              variants: item.product.variants.map((variant: any) => ({
                images: variant.images,
                variant: omit(variant, ['images']),
              })),
              product: {
                ...omit(item.product, ['category', 'merchant', 'highestCampaign', 'variants']),
              },
            },
            productVariant: {
              images: item.productVariant.images,
              variant: omit(item.productVariant, ['images']),
            },
            cartItem: { id: uuidv4(), ...omit(item, ['product', 'productVariant']) },
          })) || [],
        deliveryData: state.userData.cart.deliveryData || null,
        deliveryDate: state.userData.cart.deliveryDate || null,
        discountData: state.userData.cart.discountData || null,
        ongoingPaymentRequestId: state.userData.cart.ongoingPaymentRequestId || null,
        pricingData: {
          itemsCost: 0,
          itemsRevenue: 0,
          itemsTax: 0,
          subtotal: 0,
          shippingRevenue: 0,
          itemsDiscount: 0,
          cartDiscount: 0,
          discount: 0,
          total: 0,
          ...state.userData.cart.pricingData,
        },
        validPaymentMethods: state.userData.cart.validPaymentMethods || {
          methods: [
            $Enums.OrderPaymentMethod.CASH,
            $Enums.OrderPaymentMethod.PALOMMA__PSE,
            $Enums.OrderPaymentMethod.PALOMMA__BANCOLOMBIA,
            $Enums.OrderPaymentMethod.PALOMMA__CARD,
          ],
        },
        deliveryType: state.userData.cart.deliveryType || null,
      },
    },
  }
}
