import { QueryClient } from '@tanstack/query-core'
import { persistQueryClient } from '@tanstack/react-query-persist-client'
import { localStoragePersister } from '@centrito/app/api/localStoragePersister'

export const getQueryClient = (): QueryClient => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        refetchOnMount: false,
        retryDelay: 5000,
      },
    },
  })

  persistQueryClient({
    queryClient,
    persister: localStoragePersister,
  })

  return queryClient
}
