import React from 'react'
import { Provider as ReactReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { wrapper } from '@centrito/app/store/utils/next/wrapper'

const ReduxProvider = ({ children, ...rest }: { children: React.ReactNode }): JSX.Element => {
  const { store } = wrapper.useWrappedStore(rest)
  return (
    <ReactReduxProvider store={store}>
      <PersistGate persistor={(store as any).__persistor} loading={null}>
        {children}
      </PersistGate>
    </ReactReduxProvider>
  )
}

export default ReduxProvider
