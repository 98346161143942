export const PROD_URL = process.env.NEXT_PUBLIC_PROD_URL ?? 'https://samsam.co'
export const DEPLOY_URL = process.env.NEXT_PUBLIC_DEPLOY_URL ?? ''
// export const DEV_URL = 'http://10.0.0.58:3000' // Expo dev url
export const DEV_URL = 'http://localhost:3000' // NextJS dev url

export const PREVIEW_URL = 'https://www.staging.samsam.co'

export const SUPABASE_URL = process.env.NEXT_PUBLIC_SUPABASE_URL ?? ''
export const SUPABASE_ANON_KEY = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY ?? ''
export const SUPABASE_SERVICE_KEY = process.env.SUPABASE_SERVICE_KEY ?? ''
export const SUPABASE_STORAGE_URL = `${SUPABASE_URL}/storage/v1/object/public`

export const isDeployment = process.env.NODE_ENV === 'production'
export const isProd = isDeployment && DEPLOY_URL === PROD_URL
export const isStaging = isDeployment && !!DEPLOY_URL && DEPLOY_URL !== PROD_URL
export const isPreview = isDeployment && !(isProd || isStaging)
export const isDev = process.env.NODE_ENV === 'development'

export const isServer = typeof window === 'undefined'
export const isNext = process.env.NEXT_RUNTIME === 'nodejs'

export const isCloudBackend = SUPABASE_URL.startsWith('https://')
export const isLocalBackend = !isCloudBackend

export const ROOT_URL = isProd
  ? PROD_URL
  : isStaging
    ? DEPLOY_URL
    : isPreview
      ? PREVIEW_URL
      : DEV_URL
export const ROOT_URL_API = `${ROOT_URL}/api`
export const MERCHANT_ROOT_URL = `${ROOT_URL}/proveedores`
export const IS_MERCHANT_APP =
  isDeployment &&
  ['centrito.co', 'www.centrito.co'].includes(process.env.VERCEL_PROJECT_PRODUCTION_URL ?? '')

export const PROJECT_NAME = process.env.PROJECT_NAME ?? 'Unspecified'

// SEO config
export const TITLE = 'Samsam | Más simple, más confiable'
export const DESCRIPTION =
  'En Samsam comprar desde tu celular productos con precios justos, pago contra entrega y envío a toda Bogotá. Samsam | Más simple, más confiable !'

export const SITE_NAME = 'Samsam'
export const DEFAULT_PRICE_MARKUP = Number(process.env.DEFAULT_PRICE_MARKUP)

// export const CENTRITO_WHATSAPP_NUMBER_MESSAGEBIRD = '+573044651883'
export const CENTRITO_WHATSAPP_NUMBER = '+573202247476'
export const HELP_BUTTON_WHATSAPP_NUMBER = '+573202247476'
export const MERCHANT_HELP_BUTTON_WHATSAPP_NUMBER = '+573013011555'
export const CENTRITO_LOGIN_NUMBER = '+573202247476'

// Deprecated
// export const SUPABASE_MAGIC_LINK_URL = `${SUPABASE_URL}/auth/v1/verify`
export const NOTION_BOT_SECRET = process.env.NOTION_BOT_SECRET ?? ''

const META_CONVERSION_PIXEL_ID = process.env.NEXT_PUBLIC_META_CONVERSION_PIXEL_ID ?? ''
// TODO: Only run on prod?
export const FACEBOOK_PIXEL_SCRIPT_JS = `
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '${META_CONVERSION_PIXEL_ID}');
  fbq('track', 'PageView');
`
export const COUPON_LENGTH = 6
export const NUM_PRODUCTS_ON_LOAD = 24
export const NUM_SUGGESTIONS_PRODUCTS = 4
export const COST_DOMICILIO = 8000
export const LOGISTICS_COST_STORE = 0
export const CACHE_DURATION_IN_SECONDS = 20 * 60 // 4 minutes
export const REVALIDATE_PRODUCT_PAGE_INTERVAL = 10 * 60 // 10 minutes
export const REVALIDATE_HOME_PAGE_INTERVAL = 10 * 60 // 10 minutes
export const SHIPPING_CITIES = ['Bogotá', 'Soacha']

// place to keep regular expressions
export const REGEX_CATEGORY_NODE = /^[^|][A-Z_Ñ|]+[^|]$/
export const MAIN_COLOR_APP = '#ff004f'
export const DISABLE_LOGIN_IN_STAGING = true
export const DEFAULT_NON_PROD_TEST_ACCOUNT = process.env.NEXT_PUBLIC_TEST_ACCOUNT ?? '576666666666'
export const DEFAULT_NON_PROD_TEST_MERCHANT =
  process.env.NEXT_PUBLIC_TEST_MERCHANT ?? 'test@centrito.co'

export enum PopUpContentTypes {
  DELIVERY = 'delivery',
  SCHEDULE = 'schedule',
}

export const TEMPORAL_CLOUD_CERT = process.env.TEMPORAL_CLOUD_CERT
export const TEMPORAL_CLOUD_KEY = process.env.TEMPORAL_CLOUD_KEY
export const TEMPORAL_NAMESPACE = process.env.TEMPORAL_NAMESPACE ?? 'default'
export const TEMPORAL_SERVER = process.env.TEMPORAL_SERVER ?? ''
export const TEMPORAL_TASK_QUEUE = process.env.TEMPORAL_TASK_QUEUE ?? ''
export const TEMPORAL_SUPER_TRIGGER_TASK_QUEUE = process.env.TEMPORAL_SUPER_TRIGGER_TASK_QUEUE ?? ''

export const STATIC_PATHS_BUCKET = 'generated-static-paths'

export const IS_B2B = process.env.IS_B2B ?? 'false'

export const INTERNAL_API_TOKEN = process.env.INTERNAL_API_TOKEN ?? ''

export const MERCHANT_PROFILE_PICTURE_BUCKET_URL =
  process.env.MERCHANT_PROFILE_PICTURE_BUCKET_URL ?? ''
export const ALLIED_STORES_PAYMENT_PHOTOS_BUCKET =
  process.env.ALLIED_STORES_PAYMENT_PHOTOS_BUCKET ?? ''
export const PRODUCT_IMAGES_BUCKET_URL = process.env.PRODUCT_IMAGES_BUCKET_URL ?? ''

export const REDIS_UPSTASH_URL = process.env.REDIS_UPSTASH_URL
export const REDIS_UPSTASH_TOKEN = process.env.REDIS_UPSTASH_TOKEN

export const RETOOL_LOGIN_ENCRYPTION_KEY = process.env.RETOOL_LOGIN_ENCRYPTION_KEY ?? ''

export const ONE_SIGNAL_APP_ID = process.env.SHOPPER_ONE_SIGNAL_APP_ID ?? ''
export const ONE_SIGNAL_API_REST_KEY = process.env.SHOPPER_ONE_SIGNAL_API_REST_KEY ?? ''

export const RETOOL_RPC_API_TOKEN = process.env.RETOOL_RPC_API_TOKEN ?? ''
export const RETOOL_RPC_HOST = process.env.RETOOL_RPC_HOST ?? ''
export const RETOOL_RPC_RESOURCE_ID = process.env.RETOOL_RPC_RESOURCE_ID ?? ''

export const PUSHWOOSH_API_KEY = process.env.PUSHWOOSH_API_KEY ?? ''
export const PUSHWOOSH_APP_ID = process.env.PUSHWOOSH_APP_ID ?? ''

export const OPENAI_API_KEY = process.env.OPENAI_API_KEY ?? ''
