import { z } from 'zod'
import { OnlinePaymentRequestState } from '@centrito/db'
import { OnlinePaymentRequestStatus, OrderPaymentState } from '@centrito/db'

export const PalommaPayinStatus = z.enum(['pending', 'approved', 'cancelled', 'processing'])
export type PalommaPayinStatus = z.infer<typeof PalommaPayinStatus>

export const PalommaPayinStatusMapToOnlinePaymentRequestStatus: Record<
  PalommaPayinStatus,
  Partial<OnlinePaymentRequestStatus>
> = {
  [PalommaPayinStatus.Enum.pending]: OnlinePaymentRequestStatus.START_PENDING,
  [PalommaPayinStatus.Enum.approved]: OnlinePaymentRequestStatus.END_CLOSED,
  [PalommaPayinStatus.Enum.processing]: OnlinePaymentRequestStatus.ACTIVE_PROCESSING__PALOMMA,
  [PalommaPayinStatus.Enum.cancelled]: OnlinePaymentRequestStatus.END_CANCELLED__PALOMMA,
}

export const PalommaPayinStatusMapToOnlinePaymentRequestState: Record<
  PalommaPayinStatus,
  Partial<OnlinePaymentRequestState>
> = {
  [PalommaPayinStatus.Enum.pending]: OnlinePaymentRequestState.START_PROCESSING,
  [PalommaPayinStatus.Enum.approved]: OnlinePaymentRequestState.END_SUCCESSFUL,
  [PalommaPayinStatus.Enum.processing]: OnlinePaymentRequestState.START_PROCESSING,
  [PalommaPayinStatus.Enum.cancelled]: OnlinePaymentRequestState.END_FAILED,
}

export const PalommaPayinStatusMapToOrderPaymentState: Record<
  PalommaPayinStatus,
  OrderPaymentState
> = {
  [PalommaPayinStatus.Enum.pending]: OrderPaymentState.START_PENDING,
  [PalommaPayinStatus.Enum.approved]: OrderPaymentState.END_CAPTURED_FULL,
  [PalommaPayinStatus.Enum.processing]: OrderPaymentState.START_PENDING,
  [PalommaPayinStatus.Enum.cancelled]: OrderPaymentState.END_CANCELLED,
}
