import React, { createContext, useEffect, useMemo, useState } from 'react'
import isNil from 'lodash.isnil'
import pick from 'lodash.pick'
import { $Enums } from '@centrito/db'
import {
  ProductVariantMinimalDbPublicComposite,
  ProductVariantNanoDbPublicComposite,
} from '@centrito/api/nest/platform/database/domain'
import type {
  ProductMinimalCompositePublic,
  ProductNanoCompositePublic,
} from '@centrito/api/nest/shopper/catalog/products/domain/composites'
import { useDispatch } from '@centrito/app/store'

export interface AppStateContext {
  isScrollingToTop: boolean
  setIsScrollingToTop: React.Dispatch<React.SetStateAction<boolean>>
  selectedCategoryIndex: number
  setSelectedCategoryIndex: React.Dispatch<React.SetStateAction<number>>
  isInitialized: boolean
  selectedProduct: ProductNanoCompositePublic | null
  setSelectedProduct: React.Dispatch<React.SetStateAction<ProductNanoCompositePublic | null>>
  transformProductToMinimal: (
    product: ProductNanoCompositePublic,
  ) => ProductMinimalCompositePublic | null
  transformProductVariantToMinimal: (
    variant: ProductVariantNanoDbPublicComposite,
  ) => ProductVariantMinimalDbPublicComposite | null
  transformProductToNano: (
    product: ProductMinimalCompositePublic,
  ) => ProductNanoCompositePublic | null
  transformProductVariantToNano: (
    variant: ProductVariantMinimalDbPublicComposite,
  ) => ProductVariantNanoDbPublicComposite | null
}

const initialContext: AppStateContext = {
  isScrollingToTop: false,
  setIsScrollingToTop: () => {},
  selectedCategoryIndex: 0,
  setSelectedCategoryIndex: () => {},
  isInitialized: false,
  selectedProduct: null,
  setSelectedProduct: () => {},
  transformProductToMinimal: () => null,
  transformProductVariantToMinimal: () => null,
  transformProductToNano: () => null,
  transformProductVariantToNano: () => null,
}

export const AppStateContext = createContext<AppStateContext>(initialContext)

const AppStateProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const [isScrollingToTop, setIsScrollingToTop] = useState<boolean>(false)
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<number>(0)
  const [isInitialized, setIsInitialized] = useState<boolean>(false) // New state
  const [selectedProduct, setSelectedProduct] = useState<ProductNanoCompositePublic | null>(null)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!isInitialized) {
      setIsInitialized(true)
    }
  }, [dispatch, isInitialized])

  const transformProductToMinimal = (
    product: ProductNanoCompositePublic,
  ): ProductMinimalCompositePublic | null => {
    return {
      product: {
        id: product.product.id,
        name: product.product.name,
        description: '', // Since Nano doesn't include description
        price: product.product.priceMerchantSuggestedRetail,
        priceMerchantSuggestedRetail: product.product.priceMerchantSuggestedRetail,
        customMarkup: -1,
        customTakeRate: -1,
        status: $Enums.ProductStatus.ACTIVE__INTERNAL,
      },
      category: {
        id: product.product.categoryId,
        nodes: product.product.categoryNodes,
        shouldBePrePaid: false,
        customMarkup: 0,
        status: $Enums.CategoryStatus.ACTIVE,
      },
      merchant: {
        id: product.product.merchantId,
        name: product.product.merchantName,
        type: $Enums.MerchantType.NOT_AFFILIATED,
        takeRate: -1,
        customMarkup: -1,
      },
      variants: product.variants.map((variant) => ({
        variant: {
          ...variant.variant,
          status: $Enums.ProductStatus.ACTIVE__INTERNAL,
          customPrice: -1,
        },
        images: [{ ...variant.image }],
      })),
      pricingData: {
        priceRetail: product.pricingData.priceRetail,
        priceWholesale: 0,
        priceDiscountItemPublic: {
          amount: product.pricingData.discount,
          campaignId: null,
        },
      },
      discountPublicCampaign: product.discountPublicCampaign
        ? {
            id: 'default',
            expiresAt: new Date().toISOString(),
            validAt: new Date().toISOString(),
            type: $Enums.PromotionalCampaignDiscountPresentation.PUBLIC_STANDARD_DISCOUNT,
            rules: {
              constraints: [
                { value: product.product.id, attributeName: 'product.id', operator: '==' },
              ],
              discount: {
                type: product.discountPublicCampaign.discountType,
                amount: product.discountPublicCampaign.discountAmount,
                subject: 'ITEM',
                constraints: [
                  { value: product.product.id, attributeName: 'product.id', operator: '==' },
                ],
              },
              expiration: { type: 'STATIC_INHERIT' },
            },
          }
        : null,
    }
  }

  const transformProductVariantToMinimal = (
    variant: ProductVariantNanoDbPublicComposite,
  ): ProductVariantMinimalDbPublicComposite | null => {
    return {
      variant: {
        ...variant.variant,
        status: $Enums.ProductStatus.ACTIVE__INTERNAL,
        customPrice: -1,
      },
      images: [{ ...variant.image }],
    }
  }

  const transformProductVariantToNano = (
    variant: ProductVariantMinimalDbPublicComposite,
  ): ProductVariantNanoDbPublicComposite | null => {
    return {
      variant: pick(variant.variant, ['id', 'colorData', 'data', 'ordering']),
      image: pick(variant.images[0], ['aspectRatio', 'urlWebP']),
    }
  }

  const transformProductToNano = (
    product: ProductMinimalCompositePublic,
  ): ProductNanoCompositePublic | null => {
    return {
      product: {
        ...pick(product.product, ['id', 'name', 'priceMerchantSuggestedRetail']),
        categoryId: product.category.id,
        merchantId: product.merchant.id,
        merchantName: product.merchant.name,
        categoryNodes: product.category.nodes,
      },
      variants: product.variants.map((variant) => ({
        variant: {
          id: variant.variant.id,
          colorData: variant.variant.colorData,
          data: variant.variant.data,
          ordering: variant.variant.ordering,
        },
        image: {
          aspectRatio: variant.images[0].aspectRatio,
          urlWebP: variant.images[0].urlWebP,
        },
      })),
      pricingData: {
        priceRetail: product.pricingData.priceRetail,
        discount: product.pricingData.priceDiscountItemPublic.amount,
      },
      discountPublicCampaign: isNil(product.discountPublicCampaign)
        ? null
        : {
            campaignType: product.discountPublicCampaign.type,
            discountType: product.discountPublicCampaign.rules.discount.type,
            discountAmount: product.discountPublicCampaign.rules.discount.amount,
          },
    }
  }

  const value = useMemo(
    () => ({
      isScrollingToTop,
      setIsScrollingToTop,
      selectedCategoryIndex,
      setSelectedCategoryIndex,
      isInitialized,
      selectedProduct,
      setSelectedProduct,
      transformProductToMinimal,
      transformProductVariantToMinimal,
      transformProductToNano,
      transformProductVariantToNano,
    }),
    [isScrollingToTop, selectedCategoryIndex, isInitialized, selectedProduct],
  )

  return <AppStateContext.Provider value={value}>{children}</AppStateContext.Provider>
}

export default AppStateProvider
