import { createAsyncThunk, miniSerializeError } from '@reduxjs/toolkit'
import type { MerchantPublic } from '@centrito/api/nest/platform/database/domain'
import trpcProxyClient from '@centrito/app/api/trpc/proxyClient'
import type { MerchantGeneralInformation } from '@centrito/app/features/merchant/mi-cuenta/informacion-general/schemas'
import type { AppThunkApiConfig } from '@centrito/app/store'
import * as REDUCER_NAMES from '@centrito/app/store/slices/names'

interface UpdateMerchantProfilePayload extends MerchantGeneralInformation {}

interface UpdateMerchantProfileResponse {
  merchantProfile: MerchantPublic
}

export default createAsyncThunk<
  UpdateMerchantProfileResponse,
  UpdateMerchantProfilePayload,
  AppThunkApiConfig
>(`${REDUCER_NAMES.USER_DATA}/updateMerchantProfile`, async (payload, thunkAPI) => {
  try {
    const response = await trpcProxyClient.merchant.profile.updateMerchantProfile.mutate(payload)

    return response
  } catch (error) {
    return thunkAPI.rejectWithValue(miniSerializeError(error))
  }
})
