import React, { useEffect, useMemo } from 'react'
import { AlertCircle, CheckCircle2 } from '@tamagui/lucide-icons'
import { Toast, useToastController, useToastState } from '@tamagui/toast'
import { isClient } from 'tamagui'
import { TOAST_TYPE } from '@centrito/common/constants'
import { BodyText2 } from './text/new'

interface BaseToastProps {
  viewportName?: string
  duration?: number
}

interface ToastContentProps {
  icon: React.ReactNode
  backgroundColor: string
  textColor: string
  title: string
}

const ToastContent: React.FC<ToastContentProps> = ({ icon, backgroundColor, textColor, title }) => (
  <Toast
    y={0}
    opacity={1}
    scale={1}
    animation="quick"
    width={isClient ? '100%' : '90%'}
    backgroundColor={backgroundColor}
    shadowColor="$translucentBlack15"
    shadowOffset={{ width: 0, height: 3 }}
    shadowRadius={10}
    borderRadius={8}
    paddingRight={isClient ? undefined : '$5'}
    paddingVertical="$3"
    flexDirection="row"
    gap="$3"
  >
    {icon}
    <BodyText2 color={textColor} paddingTop={2} numberOfLines={2}>
      {title}
    </BodyText2>
  </Toast>
)

export const BaseToast: React.FC<BaseToastProps> = ({
  viewportName = 'default',
  duration = 5000,
}) => {
  const toast = useToastState()
  const { hide: hideToast } = useToastController()

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (toast && !toast.isHandledNatively) {
        hideToast()
      }
    }, duration)

    return () => clearTimeout(timeout)
  }, [toast, hideToast, duration])

  const toastContent = useMemo(() => {
    if (!toast || toast.isHandledNatively) return null

    switch (toast.customData?.preset) {
      case TOAST_TYPE.SUCCESS:
        return (
          <ToastContent
            icon={<CheckCircle2 size={24} color="$mintGreen" fill="white" />}
            backgroundColor="$mintGreen"
            textColor="$pureWhite"
            title={toast.title}
          />
        )
      case TOAST_TYPE.WARNING:
        return (
          <ToastContent
            icon={<AlertCircle size={24} color="$goldenRod" fill="white" />}
            backgroundColor="$palePeach"
            textColor="$oliveDrab"
            title={toast.title}
          />
        )
      default:
        return (
          <Toast viewportName={viewportName} duration={duration} backgroundColor="#cacaca">
            <Toast.Title fontFamily="$poppinsFont">{toast.title}</Toast.Title>
            {toast.message && (
              <Toast.Description fontFamily="$poppinsFont">{toast.message}</Toast.Description>
            )}
          </Toast>
        )
    }
  }, [toast, viewportName, duration])

  return toastContent
}
