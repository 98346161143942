export const color = {
  accent100: '#F1A6B3',
  accent200: '#E7637B',
  accent300: '#E03655',
  accent400: '#F8527F',
  Aguam: '#3DA794',
  almostWhiteGray: '#FAFAFA',
  amber: '#FFBF00',
  amber2: '#CE7813',
  amber3: '#E8A510',
  amber4: '#FFCC59',
  Aqua2: '#3DA79433',
  backgroundCheckout: '#EFF1FC',
  backgroundGrey: '#F4F5FB',
  baseBlack: '#0A0A0B',
  baseWhite: '#FFFFFF',
  blackTranslucent90: '#202020E5',
  blueEditing: '#95BBFF80',
  blueSelection: '#95BBFF4D',
  blueTranslucent5: '#D9DAFF',
  brightBlue: '#3141F2',
  brightGray: '#EBF0F1',
  brightOrange: '#FF9400',
  brightOrange20: '#FF940033',
  brightRed: '#FF004F',
  brightRedTranslucent10: '#FF004F1A',
  brightTeal: '#99B3B9',
  brightTeal2: '#DEE5E7',
  centritoRed: '#FF004F',
  darkBlack: '#14181F',
  darkBlue: '#011627',
  darkGray: '#333333',
  darkSamSamBlue: '#00264E',
  deepNavy: '#011627',
  deepShopperBlue: '#06264C',
  deliveredGreen: '#0DD92C',
  deliveredGreen10: '#0DD92C1A',
  disabledGray: '#C0C0C0',
  dustyGreen: '#99B3B9',
  emeraldGreen: '#40CA55',
  error: '#C9372C',
  error100: '#FFEDEC',
  error200: '#EAA49E',
  error300: '#DA5F56',
  error400: '#9C2B22',
  error500: '#6F1E18',
  forYouPink: '#FF004F1F',
  goldenRod: '#D0A30B',
  Gray1: '#333333',
  Gray2_5: '#AAAAAA',
  Gray2: '#7F7F7F',
  Gray3_5: '#F5F5F5',
  Gray3: '#E9E9EA',
  Gray4: '#FAFAFA',
  Gray5: '#605F5F',
  Gray6: '#727477',
  info: '#0C66E4',
  info100: '#D4E5FD',
  info200: '#5497F6',
  info300: '#052D65',
  info400: '#07397F',
  invalidRed: '#e21a1a',
  lighterGray: '#B3B3B3',
  lightSkyGray: '#EBF0F1',
  mainShopperBlue: '#546EE5',
  mediumGray: '#7F7F7F',
  midToneGray: '#AAAAAA',
  mintGreen: '#2EC285',
  neutral100: '#FAFAFA',
  neutral1000: '#343434',
  neutral200: '#F3F3F3',
  neutral300: '#E7E7E7',
  neutral400: '#CECECE',
  neutral500: '#B2B2B2',
  neutral600: '#858585',
  neutral700: '#6F6F6F',
  neutral800: '#595959',
  neutral900: '#434343',
  oliveDrab: '#65600A',
  Orange: '#FF9400',
  orangeTanslucent5: '#FF94000D',
  palePeach: '#FCEEC2',
  paleTranslucentOrange: '#FF94001A',
  primary100: '#D8E8FC',
  primary1000: '#093C78',
  primary200: '#B0D2F9',
  primary300: '#89BBF6',
  primary400: '#61A4F3',
  primary500: '#3A8EF0',
  primary600: '#1377ED',
  primary700: '#0F63C7',
  primary800: '#0C509F',
  primary900: '#08346C',
  progressBarGreen: '#3DA794',
  pureBlack: '#000000',
  pureWhite: '#FFFFFF',
  purple: '#4724A3',
  opaquePurple: '#B153A3',
  reviewedPurple: '#4724A3',
  reviewedPurpleTranslucent30: '#4724A333',
  samsamBlue: '#4646F5',
  samsamBlue2: '#4747ED',
  samsamBlue2Translucent20: '#4747ED33',
  samsamBlue2Translucent30: '#4747ED4D',
  samsamBlueDarker: '#3838BB',
  samsamBlueTranslucent10: '#4646F51A',
  samsamBlueTranslucent15: '#4646F526',
  samsamBlueTranslucent20: '#4646F533',
  samsamBlueTranslucent30: '#4646F54D',
  samsamOrange: '#F49428',
  samsamOrangeDarker: '#CD7512',
  secondary100: '#DDE2FA',
  secondary1000: '#0D1A55',
  secondary200: '#BBC5F5',
  secondary300: '#98A8EF',
  secondary400: '#768BEA',
  secondary500: '#3554E0',
  secondary600: '#2040D1',
  secondary700: '#1B36B2',
  secondary800: '#162D93',
  secondary900: '#122374',
  slateGreen: '#55808A',
  softGray: '#E9E9EA',
  softShopperBlue: '#95BBFF',
  standardGray: '#808080',
  standardGray2: '#858585',
  standardGray3: '#6F6F6F',
  standardGray4: '#CECECE',
  standardGray5: '#D9D9D9',
  standardGray6: '#E7E7E7',
  success: '#1F845A',
  success100: '#B9EFD8',
  success200: '#82E2BA',
  success300: '#2EC285',
  tealGreen: '#3DA794',
  tertiary: '#FFBF00',
  tertiary100: '#FFF6DB',
  tertiary1000: '#664C00',
  tertiary200: '#FFEDB6',
  tertiary300: '#FFE492',
  tertiary400: '#FFDA6D',
  tertiary500: '#FFD149',
  tertiary600: '#FFC824',
  tertiary700: '#FFC824',
  tertiary800: '#B38600',
  tertiary900: '#8C6900',
  translucentBlack15: '#00000026',
  translucentBlack25: '#00000040',
  translucentBlack50: '#00000080',
  translucentBrightRed: '#FF004F33',
  translucentBrightRed20: '#FF004F1A',
  translucentDeliveredGreen20: '#0DD92C33',
  translucentOrange: '#FF9400A1',
  translucentOrange20: '#FF940033',
  translucentOrange40: '#FF940066',
  translucentSoftGray: '#E9E9EAB3',
  translucentTeal: '#3DA79433',
  transparentBlack: '#FFFFFF00',
  transparentBlack2: '#0000001E',
  transparentBlack3: '#000000d0',
  ultraLightGray: '#F7F7F7',
  vibrantGreen: '#47BE85',
  vividPurple: '#9747FF',
  warning: '#F5CD47',
  warning100: '#FCEEC2',
  warning200: '#F8DE84',
  warning300: '#D0A30B',
  warning500: '#65600A',
  warningBackground: '#99B3B93D',
  white: '#FAFAFA',
}
