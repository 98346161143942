import { z } from 'zod'
import { PalommaPaymentStatus as DbPalommaPayoutStatus } from '@centrito/db'

export const PalommaPaymentStatus = z.enum(['processing', 'approved', 'cancelled', 'other'])
export type PalommaPaymentStatus = z.infer<typeof PalommaPaymentStatus>

export const PalommaPayoutStatusMap: Record<PalommaPaymentStatus, DbPalommaPayoutStatus> = {
  [PalommaPaymentStatus.Enum.processing]: DbPalommaPayoutStatus.PROCESSING,
  [PalommaPaymentStatus.Enum.approved]: DbPalommaPayoutStatus.APPROVED,
  [PalommaPaymentStatus.Enum.cancelled]: DbPalommaPayoutStatus.CANCELLED,
  [PalommaPaymentStatus.Enum.other]: DbPalommaPayoutStatus.OTHER,
}
