// listenerMiddleware.ts
import { createListenerMiddleware } from '@reduxjs/toolkit'
import isNil from 'lodash.isnil'
import type { AppDispatch, RootState } from '@centrito/app/store'
import localStorageClient from '@centrito/app/utils/services/localStorage'

export const fixCartHackMiddleware = createListenerMiddleware()

export const startAppListening = fixCartHackMiddleware.startListening.withTypes<
  RootState,
  AppDispatch
>()

startAppListening({
  predicate: (action) => action.type !== undefined,
  effect: (_, listenerApi) => {
    const state = listenerApi.getState()
    if (isNil(state.userData.cart?.items[0]?.product.pricingData.priceRetail)) {
      const fixedState = {
        ...state,
        userData: {
          ...state.userData,
          cart: {
            ...state.userData.cart,
            items: [],
          },
        },
      }
      const serializedState = JSON.stringify(
        Object.fromEntries(Object.entries(fixedState).map(([k, v]) => [k, JSON.stringify(v)])),
      )
      if (!isNil(localStorageClient)) localStorageClient.setItem('persist:root', serializedState)
    }
  },
})

export default fixCartHackMiddleware
