import type { SessionProduct } from '@centrito/app/store/slices/userData/types'
import { getTimeThreshold } from './getTimeThreshold'

const MAX_SCROLL_COUNT = 4

/**
 * Filters out expired products based on time threshold and scroll count
 * @param products Array of session products to filter
 * @returns Filtered array of session products
 */
export const clearExpiredProducts = (products: SessionProduct[]): SessionProduct[] => {
  const now = Date.now()
  return products.filter((product, index) => {
    const timeThreshold = getTimeThreshold(index)
    const isWithinTimeThreshold = now - product.timestamp <= timeThreshold
    const isWithinScrollThreshold = product.scrollCount <= MAX_SCROLL_COUNT

    return isWithinTimeThreshold && isWithinScrollThreshold
  })
}
