// Preserves stack of original error
export class BaseError extends Error {
  constructor(error: unknown, name: string) {
    if (typeof error === 'string') {
      super(error)
    } else if (error instanceof Error) {
      super(error.message)
      if (error.stack) {
        this.stack = error.stack
      }
      Object.assign(this, error)
    } else {
      super(`Unknown error: ${JSON.stringify(error)}`)
      if (typeof error === 'object' && error !== null) {
        Object.assign(this, error)
      }
    }
    this.name = name
  }
}
